import React from "react"
import Seo from "../components/seo"
import Contacts from "../components/contacts"
import Table from "../components/shared/policies/table"
import Title from "../components/shared/policies/title"
import Subtitles from "../components/shared/policies/subtitles"
import Container from "../components/shared/policies/termsContainer"
import Paragraphs from "../components/shared/policies/paragraphs"
import { Link } from "gatsby"
import Text from "../components/shared/policies/textContainer"
import NewFooter from "../components/shared/NewFooter"

function CookiePolicy() {
  return (
    <>
      <Container>
        <Seo title="Cookie Policy" />
        <Title>PDFPro.com Cookie Policy</Title>
        <Text>
          <Paragraphs>
            This policy contains full information about the cookies that we use
            on pdfpro.com (hereinafter referred to as the "Site") and the tools
            and knowledge to manage them.
          </Paragraphs>
          <Paragraphs>
            We are continuously making further improvements and changes to the
            ways we use cookies and we will keep this page updated with the
            cookies we use.
          </Paragraphs>
          <Paragraphs>
            Please refer also to our <Link to="/privacy">Privacy Policy</Link>.
          </Paragraphs>
          <Subtitles>What are cookies?</Subtitles>
          <Paragraphs>
            Cookies are small text files that the websites visited by users send
            through to their terminals (computers, tablets, smartphones,
            notebooks, etc.), usually to the browser, where they are stored to
            then be retransmitted to the same websites when the same users
            subsequently revisit the sites.
          </Paragraphs>
          <Paragraphs>
            Please note that cookies will not damage your device.
          </Paragraphs>
          <Subtitles>Cookie categories</Subtitles>
          <Subtitles size="medium" height="medium">
            Technical cookies
          </Subtitles>
          <Subtitles size="small" height="small">
            Strictly necessary cookies
          </Subtitles>
          <Paragraphs>
            These cookies are essential for you to move around the Site, to use
            its features and provide the services you have requested, such as
            access to restricted areas of the website.
          </Paragraphs>
          <Paragraphs>
            They can, for example, memorize previous actions (e.g., entered
            text, etc.) when navigating back to a page in the same session,
            manage security tokens for different services within the Site to
            identify the status of the visitor (e.g., registered or
            unregistered), etc.
          </Paragraphs>
          <Paragraphs>
            <u>
              Without these cookies we cannot provide the services you request
              (such as, shopping cart, e-billing, etc.).
            </u>
          </Paragraphs>
          <Subtitles>Performance cookies</Subtitles>
          <Paragraphs>
            These cookies can be session or persistent, and their use is limited
            to the performance and improvement of the Site features, such as
            collecting information on how a visitor uses the Site.
          </Paragraphs>
          <Paragraphs>
            They can do, for example, web analytics, error handling, test
            different website page structures, etc.
          </Paragraphs>
          <Paragraphs>
            <u>
              These cookies do not collect information that can identify you, as
              all information collected by them are aggregated in an anonymous
              form and are only used to improve the functionality of the Site.
            </u>
          </Paragraphs>
          <Subtitles>Functional cookies</Subtitles>
          <Paragraphs>
            These cookies can be session or persistent, and are usually the
            result of a user action, but they can also be implemented when
            providing a service not specifically requested but simply offered to
            users.
          </Paragraphs>
          <Paragraphs>
            They can, for example, remember the settings that a user has applied
            to a website (such as layout, font size, preferences, colors, etc.),
            remember a choice so that users are no longer requested to fill out
            a questionnaire, detect if a service has already been offered,
            provide information to allow access to an optional service (such as
            the offer of a live chat session), fulfil a user request (such as
            submitting a comment).
          </Paragraphs>
          <Subtitles>Profilation cookies</Subtitles>
          <Subtitles size="medium" height="medium">
            Third-party profiling cookies for marketing / retargeting purposes
          </Subtitles>
          <Paragraphs>
            These cookies are used by our partners, upon your prior consent, in
            order to profile users for advertising and retargeting purposes.
          </Paragraphs>
          <Paragraphs>
            They can, for example, create user profiles to serve ads that are in
            line with your preferences showed as you surf the web; record your
            choices and activity on our website to carry out statistical
            analysis – for us or for third parties – by tracking your
            preferences and browsing behavior, serve customized ads to you,
            integrate some common feature of the major social media and provide
            it within the site, etc.
          </Paragraphs>
          <Subtitles>Cookies used on this Site</Subtitles>
          <div className="table-container">
            <Table>
              <thead>
                <tr>
                  <th>Cookies</th>
                  <th>Type</th>
                  <th>Source</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Google analytics cookies (_ga, _gid, _gat,AMP_TOKEN) </td>
                  <td>Profiling</td>
                  <td>Google Analytics</td>
                  <td>
                    These cookies are used by our partners in order to profile
                    users for advertising and retargeting purposes. They can,
                    for example, create user profiles to serve ads that are in
                    line with your preferences showed as you surf the web;
                    record your choices and activity on our website to carry out
                    statistical analysis – for us or for third parties – by
                    tracking your preferences and browsing behavior, serve
                    customized ads to you, integrate some common feature of the
                    major social media and provide it within the site, etc. For
                    more information on these cookies, please click here{" "}
                    <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=en">
                      https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=en
                    </a>{" "}
                    or if you want to opt-out click here{" "}
                    <a href="https://tools.google.com/dlpage/gaoptout">
                      https://tools.google.com/dlpage/gaoptout
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Internal campaign performance cookie (url)</td>
                  <td>Performance</td>
                  <td>PDF Pro</td>
                  <td>
                    Campaign tracking by using anonymous data to see product
                    performance on the market, placement of coupons and special
                    offers, placement of unique anonymous id for in app links.
                  </td>
                </tr>
                <tr>
                  <td>Consent cookie (_consent)</td>
                  <td>Functional</td>
                  <td>PDF Pro</td>
                  <td>
                    This cookie is created in the event that the user has
                    accepted our standard cookie terms and it lasts for 1 year.
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <Subtitles>How can I disable Cookies?</Subtitles>
          <Paragraphs>
            We remind you that, in compliance with current legislation on
            cookies, your prior consent is not necessary for technical cookies.
          </Paragraphs>
          <Paragraphs>
            Anyway, if you do not agree with the use of all or any cookie, you
            must configure the browser settings and disable the cookies or stop
            using the Site. By disabling cookies, however, the Site or some of
            its features may not work properly.
          </Paragraphs>
          <Paragraphs>
            To modify the use of cookies and block/delete them on the device,
            simply enter the browser settings.
          </Paragraphs>
          <Paragraphs>
            Configurations of the cookies may be different in each browser, even
            though the steps are very similar. For more details on the
            procedure, visit{" "}
            <a href="http://www.aboutcookies.org ">
              http://www.aboutcookies.org{" "}
            </a>{" "}
            or see the “Help” section of your specific browser.
          </Paragraphs>
          <Paragraphs>
            To learn more about cookies and to manage your preferences on
            third-party cookies please visit{" "}
            <a href=" http://www.youronlinechoices.com">
              http://www.youronlinechoices.com
            </a>
          </Paragraphs>
        </Text>
      </Container>
      <NewFooter />
    </>
  )
}

export default CookiePolicy
